export const useVariantsDictionary = () => {
    const { $lang } = useNuxtApp()
    const dictionary = {
        variants: {
            offer: {
                variant: 'offer' as 'offer',
                path: `/${$lang.routes.offers}`,
                wordPlural: $lang.lib.variant_dict.offer.plural,
                wordSingular: $lang.lib.variant_dict.offer.singular,
                wordSingularOne: $lang.lib.variant_dict.offer.singular_one,
                wordPlain: $lang.lib.variant_dict.offer.plain,
            },
            coupon: {
                variant: 'coupon' as 'coupon',
                path: `/${$lang.routes.coupons}`,
                wordPlural: $lang.lib.variant_dict.coupon.plural,
                wordSingular: $lang.lib.variant_dict.coupon.singular,
                wordSingularOne: $lang.lib.variant_dict.coupon.singular_one,
                wordPlain: $lang.lib.variant_dict.coupon.plain,
            },
            chat: {
                variant: 'chat' as 'chat',
                path: `/${$lang.routes.forum}`,
                wordPlural: $lang.lib.variant_dict.chat.plural,
                wordSingular: $lang.lib.variant_dict.chat.singular,
                wordSingularOne: $lang.lib.variant_dict.chat.singular_one,
                wordPlain: $lang.lib.variant_dict.chat.plural,
            },
            forum: {
                variant: 'forum' as 'forum',
                path: `/${$lang.routes.forum}`,
                wordPlural: $lang.lib.variant_dict.chat.plural,
                wordSingular: $lang.lib.variant_dict.chat.singular,
                wordSingularOne: $lang.lib.variant_dict.chat.singular_one,
            },
        },
        defaultImages: {
            coupon: 'https://media.megadescuentos.com/defaults/default-cupon.svg',
            offer: 'https://media.megadescuentos.com/defaults/default-oferta.svg',
            store: 'https://media.megadescuentos.com/defaults/default-stores.svg',
            category: 'https://media.megadescuentos.com/defaults/default-categories.svg',
            novelty: 'https://media.megadescuentos.com/defaults/novelties.svg',
            event: 'https://media.megadescuentos.com/events.svg',
        },
    }

    return dictionary
}
